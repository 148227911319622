import { render, staticRenderFns } from "./UserDropdown.vue?vue&type=template&id=66473949&"
import script from "./UserDropdown.vue?vue&type=script&lang=js&"
export * from "./UserDropdown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/builds/devstark/itg/itg-custom-illustrations/front/components/UiIcon.vue').default,UiDropdownItem: require('/builds/devstark/itg/itg-custom-illustrations/front/components/UiDropdownItem.vue').default})
